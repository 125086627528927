import React, { useCallback, useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/LaunchOutlined';
import { ButtonProps, styled } from '@mui/material';
import { defaultBorderRadius } from '../../../theme/components';
import { OnClickHandler } from '../../../types';
import { typographyThemeOptions } from '../../../theme/typography';
import OutlinedButton from '../OutlinedButton';
import { PatientService } from '../../../../core/services/patient.service';
import { Patient } from '@models';
import { Color } from '../../../theme/colors';

type ElationChartButtonProps = {
  buttonSize?: 'large' | 'medium' | 'small';
  patient: Patient;
  patientService: PatientService;
} & ButtonProps;

export function ElationChartButton({
  buttonSize = 'small',
  patient,
  patientService,
  ...props
}: ElationChartButtonProps): React.JSX.Element {
  const [elationLink, setElationLink] = useState<string>();

  const onNavigateToChart: OnClickHandler = useCallback(() => {
    window.open(elationLink, '_blank');
  }, [elationLink]);

  useEffect(() => {
    patientService
      .getElationPatientLinkByPatientId(patient.id)
      .then((link: string) => {
        // If the link has no user, we don't want to show the button. If valid, the link will end with a number.
        // However, we need to update it in case this component is reused (changing patients quickly).
        setElationLink(link);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [patient.id, patientService]);

  const StyledOutlinedButton = styled(OutlinedButton)(({ theme }) =>
    theme.unstable_sx({
      ...typographyThemeOptions.subtitle2,
      textWrap: 'nowrap',
      backgroundColor: Color.BaseGray,
      color: Color.Slate100,
      '&:hover': {
        backgroundColor: Color.BaseGray,
        color: Color.Slate100,
      },
      ...props.sx,
      radius: defaultBorderRadius,
      maxHeight: '38px',
    })
  );

  if (elationLink && !elationLink.endsWith('/')) {
    return (
      <StyledOutlinedButton size={buttonSize} onClick={onNavigateToChart} endIcon={<LaunchIcon />}>
        Elation Chart
      </StyledOutlinedButton>
    );
  }

  return null;
}
export default ElationChartButton;
